<template>
  <div class="container flex justify-content-center align-items-center p-3">
    <Card class="p-2 shadow-8">
      <template #header>
        <!-- <img alt="user header" src="assets/logo_pxcom.png"> -->
        <h1 class="my-3 text-center font-light">Hortus Lab</h1>
      </template>
      <template #title>
        <!-- Login -->
      </template>
      <template #content>
        <form class="p-fluid" @keyup.enter="signin">
          <div class="p-field my-5">
            <div class="p-float-label">
              <InputText
                id="username"
                v-model="v$.username.$model"
                :class="{ 'p-invalid': v$.username.$invalid && submitted }"
                :disabled="isLoading"
              />
              <label
                for="username"
                :class="{ 'p-error': v$.username.$invalid && submitted }"
                >{{i18n.$t('username')}}</label
              >
            </div>
            <small
              v-if="
                (v$.username.$invalid && submitted) ||
                v$.username.$pending.$response
              "
              class="p-error"
            >
              {{ v$.username.required.$message.replace("Value", "Username") }}
            </small>
          </div>
          <div class="p-field p-my-5">
            <div class="p-float-label">
              <Password
                id="password"
                v-model="v$.password.$model"
                :class="{ 'p-invalid': v$.password.$invalid && submitted }"
                :toggleMask="!isLoading"
                :feedback="false"
                :disabled="isLoading"
              ></Password>
              <label
                for="password"
                :class="{ 'p-error': v$.password.$invalid && submitted }"
                >{{i18n.$t('password')}}</label
              >
            </div>
            <small
              v-if="
                (v$.password.$invalid && submitted) ||
                v$.password.$pending.$response
              "
              class="p-error"
              >{{
                v$.password.required.$message.replace("Value", "Password")
              }}</small
            >
          </div>
        </form>
      </template>
      <template #footer>
        <div class="flex justify-content-center">
          <Button
            v-if="false"
            label="Forgot password?"
            class="p-button-text p-button-secondary"
          />
          <Button
            :label="i18n.$t('signin')"
            icon="pi pi-sign-in"
            :loading="isLoading"
            @click="signin"
          />
        </div>
        <Message v-if="hasError" severity="error">{{ hasError }}</Message>
        <hr />
        <div class="text-center comment">
          {{ copyright }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from "primevue/button";
import Message from "primevue/message";
import Card from "primevue/card";
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "../../../src/i18nPlugin";
import { useAuth } from "../../compositions/useAuth";

export default {
  components: { Button, Message, Card, InputText, Password },
  setup: () => {
    const { login } = useAuth();
    const i18n = useI18n();
    return {
      login,
      i18n,
      v$: useVuelidate(),
    };
  },
  computed: {
    copyright() {
      const currentDate = new Date().getFullYear();
      if (2021 === currentDate) {
        return `© ${currentDate}`;
      }

      return `© 2021 - ${currentDate}`;
    },
  },
  data: () => ({
    username: "",
    password: "",
    isLoading: false,
    submitted: false,
    hasError: false,
  }),
  validations() {
    return {
      username: {
        required,
      },
      password: {
        required,
      },
    };
  },
  methods: {
    signin() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }

      this.isLoading = true;

      this.login({ username: this.username, password: this.password })
        .then(() => {
          this.hasError = null;
          this.$router.push("/");
        })
        .catch((err) => {
          this.hasError = err.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  /* background-color: rgb(150, 150, 150); */
  background: rgb(103, 164, 255);
  background: radial-gradient(
    circle,
    rgb(85, 138, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.container > * {
  max-width: 400px;
}
</style>
