<template>
  <div class="container flex justify-content-center align-items-center p-3">
    <Card class="p-2 shadow-8">
      <template #header>
        <!-- <img alt="user header" src="assets/logo_pxcom.png"> -->
        <h1 class="my-3 text-center font-light">Hortus Lab</h1>
      </template>
      <template #title>
        <!-- Login -->
      </template>
      <template #content>
        <br/>
        Logout
        <br/>
      </template>
      <template #footer>
        <hr />
        <div class="text-center comment">
          {{ copyright }}
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Card from "primevue/card";
import { useI18n } from "../../../src/i18nPlugin";
import { useAuth } from "../../compositions/useAuth";

export default {
  components: { Card },
  setup: () => {
    const { logout } = useAuth()
    const i18n = useI18n()
    const router = useRouter()

    onMounted(() => {
      logout()
      setTimeout(() => {
        console.log('push')
        router.push("/")
      }, 2000)
    })

    return {
      i18n
    };
  },
  computed: {
    copyright() {
      const currentDate = new Date().getFullYear();
      if (2021 === currentDate) {
        return `© ${currentDate}`;
      }

      return `© 2021 - ${currentDate}`;
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  /* background-color: rgb(150, 150, 150); */
  background: rgb(103, 164, 255);
  background: radial-gradient(
    circle,
    rgb(85, 138, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.container > * {
  max-width: 400px;
}
</style>
